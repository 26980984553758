<template>
  <div class="login-wrapper">
    <div class="logo"></div>
    <div class="login-form">
      <div class="login-input">
        <div class="input-item">
          <label for="tel">手机号</label>
          <input id="tel" type="tel" v-model="loginParams.phone" @click="handleFocus" />
        </div>
        <div class="input-item">
          <label for="number">验证码</label>
          <input id="number" type="text" v-model="loginParams.code" maxlength="6" />
          <div :class="{ send: true, noSend: codeCount < 60 }" @click="handleSendCode">{{ codeText }}</div>
        </div>
      </div>
      <div class="login-confirm" @click="handleRegister"><span>登录</span></div>
    </div>
    <!-- 拖动条 -->
    <DragVerify v-if="verifyFlag" @triggerVerify="triggerVerify" />
  </div>
</template>

<script setup>
import { showToast } from "vant";
import { projectApi } from "@/service";
import proofStore from "@/store/proof";
import DragVerify from "./DragVerify.vue";

const emit = defineEmits(["triggerLogin"]);

const { openid, wxtoken } = proofStore();

const handleFocus = (e) => {
  e.target.focus();
};

/** 注册参数 */
const loginParams = ref({ phone: "", code: "" });

/** 验证码 */
let codeTimer = null;
const codeCount = ref(60);
const codeText = ref("发送验证码");
const startCountDown = () => {
  codeTimer && clearTimeout(codeTimer);
  if (codeCount.value > 0) {
    codeCount.value -= 1;
    codeText.value = `重新发送 ${codeCount.value}s`;
    codeTimer = setTimeout(() => startCountDown(), 1000);
  } else {
    codeCount.value = 60;
    codeText.value = `发送验证码`;
  }
};

/** 验证条 */
const verifyFlag = ref(false);
const triggerVerify = (flag) => {
  verifyFlag.value = flag;
  if (!flag) {
    projectApi({ method: "sendcode", phone: loginParams.value.phone, openid, wxtoken }).then(({ msg, sucess }) => {
      if (msg) {
        showToast(msg);
      }
      if (sucess === 1) {
        startCountDown();
      }
    });
  }
};

const handleSendCode = () => {
  if (loginParams.value.phone && codeCount.value === 60) {
    triggerVerify(true);
  }
};

/** 登录 */
const handleRegister = async () => {
  const { phone, code } = loginParams.value;
  if (loginParams.value.phone && loginParams.value.code) {
    await projectApi({ method: "register", phone, openid, code }).then(({ msg, sucess }) => {
      if (msg) {
        showToast(msg);
      }
      if (sucess === 1) {
        emit("triggerLogin", false);
      }
    });
  }
};

onUnmounted(() => {
  codeTimer && clearTimeout(codeTimer);
});
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
