<template>
  <div class="popup-wrapper">
    <img class="close-popup" :src="close" @click="() => emit('handleTriggerRule', false)" />
    <div class="popup-bg answer-bg">
      <div class="popup-headline">活动规则</div>
      <div class="popup-subtitle">规则</div>
      <div id="lottoRule" class="lotto-rule">
        <!-- <p>
          超级大乐透基本投注是指从前区号码中任选五个号码，并从后区号码中任选两个号码的组合进行投注。其中，<b>前区号码</b>由<b>01—35</b>共三十五个号码组成，<b>后区号码</b>由<b>01—12</b>共十二个号码组成。<b>每注基本投注金额人民币2元</b>。
        </p>
        <p>购买者在基本投注的基础上，可对购买的每注号码进行一次追加投注，每注追加投注金额人民币1元。</p> -->
        <p><b>活动时间</b></p>
        <p class="m10">2024年11月XX日 - 11月XX日</p>

        <p><b>活动玩法</b></p>
        <p class="m10">
          活动期间，南京体彩公益列车将停靠多个站点，用户需在每站完成任务，如答题和抽取大乐透幸运号码。完成所有站点任务后即可参与抽奖，每人每天最多可抽奖3次，答题次数不限（每日抽奖时间为9:00-
          22:00）。
        </p>

        <p><b>奖品设置</b></p>
        <p class="m10">京东E卡、腾讯视频月卡、幸运蓝海电影券（南京区域）。</p>

        <p><b>违规处理</b></p>
        <p>如用户在活动中有作弊行为，主办方有权取消其参与资格，保留进一步追责的权利。</p>
      </div>
      <div class="popup-btn" @click="() => emit('handleTriggerRule', false)"><span>知道了</span></div>
    </div>
  </div>
</template>

<script setup>
import close from "@/assets/images/close.png";

const emit = defineEmits(["handleTriggerRule"]);
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";
@import "./index.scss";
</style>
